import { call, put, takeEvery } from "redux-saga/effects";
import { surveyAnalysisSummaryApi } from "../../../../../../services/surveys/survey-analysis/surveyAnalysisApi";

// Login Redux States

import {
  getAssessmentAnalysisSummaryError,
  getAssessmentAnalysisSummarySuccess,
} from "./actions";

import { GET_ASSESSMENT_ANALYSIS_SUMMARY_REQUEST } from "./actionTypes";

function* getAnalysisSummaryOfAssessment({ payload: surveyPayload }) {
  try {
    const response = yield call(surveyAnalysisSummaryApi, surveyPayload);
    if (response && response.success) {
      yield put(getAssessmentAnalysisSummarySuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getAssessmentAnalysisSummaryError(error));
  }
}

function* assessmentSummarySaga() {
  yield takeEvery(
    GET_ASSESSMENT_ANALYSIS_SUMMARY_REQUEST,
    getAnalysisSummaryOfAssessment
  );
}

export default assessmentSummarySaga;
