import {
  EDIT_PAYMENT,
  PAYMENT_ERROR,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  RESET_PAYMENT_FLAG,
} from "./actionTypes";

export const editPayment = (billingInfo) => {
  return {
    type: EDIT_PAYMENT,
    payload: { billingInfo },
  };
};

export const paymentRequest = (billingInfo) => {
  return {
    type: PAYMENT_REQUEST,
    payload: billingInfo,
  };
};
export const paymentSuccess = (billingInfo) => {
  return {
    type: PAYMENT_SUCCESS,
    payload: billingInfo,
  };
};

export const paymentError = (error) => {
  return {
    type: PAYMENT_ERROR,
    payload: error,
  };
};

export const resetPaymentFlag = () => {
  return {
    type: RESET_PAYMENT_FLAG,
  };
};
