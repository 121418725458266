import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const CompleteQuizView = lazy(() =>
  import("../pages/quiz/CompleteQuizView.js")
);

const QuizCreation = lazy(() => import("../pages/quiz/QuizCreation.js"));

const EmailForm = lazy(() => import("../pages/forms/emails/EmailForm"));

const DashboardAnalytics = lazy(() => import("../pages/DashboardAnalytics"));

//pages
const BasicSuccessMsg = lazy(() =>
  import("../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg")
);
const Maintenance = lazy(() => import("../pages/Maintenance/Maintenance"));

const UnsubscribePage = lazy(() =>
  import("../pages/AuthenticationInner/UnsubscribePage.js")
);

//login
const Login = lazy(() => import("../pages/Authentication/Login"));

// forget password
const ForgetPasswordPage = lazy(() =>
  import("../pages/Authentication/ForgetPassword")
);

// logout
const Logout = lazy(() => import("../pages/Authentication/Logout"));

// register
const Register = lazy(() => import("../pages/Authentication/Register"));

const UserProfile = lazy(() =>
  import("../pages/Profile/SimplePage/SimplePage")
);

// reset-password
const ResetPasswordForm = lazy(() =>
  import("../pages/AuthenticationInner/PasswordReset/ResetPasswordForm")
);

// error page
const Alt401 = lazy(() => import("../pages/AuthenticationInner/Errors/Alt401"));
const Error500 = lazy(() =>
  import("../pages/AuthenticationInner/Errors/Error500")
);

// permissions
const PermissionForm = lazy(() =>
  import("../pages/forms/permissions/PermissionForm")
);
const PermissionsView = lazy(() =>
  import("../pages/permissions/PermissionsView")
);

const PackageUpdateView = lazy(() =>
  import("../pages/Profile/Billing/PackageUpdateView.js")
);

// roles
const RolesForm = lazy(() => import("../pages/forms/roles/RoleForm"));
const RolePermissions = lazy(() => import("../pages/roles/RolePermissions"));
const RolesView = lazy(() => import("../pages/roles/RolesView"));

// admin's defined user/team
const TeamForm = lazy(() => import("../pages/forms/team/TeamForm"));

// survey
const CompleteSurveyView = lazy(() =>
  import("../pages/surveys/CompleteSurveyView.js")
);
const PublishSurvey = lazy(() => import("../pages/surveys/PublishSurvey"));
const PublishQuiz = lazy(() => import("../pages/quiz/PublishQuiz"));

const SurveyCreation = lazy(() => import("../pages/surveys/SurveyCreation.js"));

const SurveyView = lazy(() => import("../pages/surveys/SurveyView"));
const QuestionBankView = lazy(() =>
  import("../pages/surveys/question-bank/QuestionBankView.js")
);

const AddAudience = lazy(() => import("../pages/audience/AddAudience"));
const AddChildNodes = lazy(() =>
  import("../pages/tree/components/AddChildNodes.js")
);

const AddAttributes = lazy(() => import("../pages/audience/AddAttributes"));
const AudienceView = lazy(() => import("../pages/audience/AudienceView"));

const AddEmailTemplate = lazy(() =>
  import("../pages/audience/template/AddEmailTemplate")
);
const TemplateView = lazy(() =>
  import("../pages/audience/template/TemplateView")
);

const EmailView = lazy(() => import("../pages/EmailAttach/EmailView"));
const BillingPayment = lazy(() =>
  import("../pages/Profile/Billing/BillingPayment")
);
const AnalyzeResponses = lazy(() =>
  import("../pages/surveys/analyze-responses/AnalyzeResponses")
);

const CreateSurveyInitiate = lazy(() =>
  import("../pages/surveys/CreateSurveyInitiate.js")
);
const CreateQuizInitiate = lazy(() =>
  import("../pages/quiz/CreateQuizInitiate.js")
);
const SingleDashboardView = lazy(() =>
  import("../pages/surveys/analyze-responses/dashboard/SingleDashboardView")
);
const QuestionSentiments = lazy(() =>
  import("../pages/surveys/analyze-responses/sentiments/QuestionSentiments.js")
);
const TeamView = lazy(() => import("../pages/team-managment/TeamView"));
const PublicDashboard = lazy(() =>
  import("../pages/surveys/analyze-responses/dashboard/PublicDashboard")
);

const AnalyzeQuiz = lazy(() =>
  import("../pages/quiz/analyze-responses/AnalyzeResponses")
);
const SingleDashboardQuiz = lazy(() =>
  import("../pages/quiz/analyze-responses/dashboard/SingleDashboardView")
);

const PublicDashboardQuiz = lazy(() =>
  import("../pages/quiz/analyze-responses/dashboard/PublicDashboard")
);

const InformationPage = lazy(() =>
  import("../pages/Information/Information.js")
);
const SearchDetails = lazy(() =>
  import("../pages/Information/SearchDetails.js")
);
const EmbeddedCode = lazy(() => import("../pages/embedded/GenEmbedded.js"));
const faqs = lazy(() => import("../pages/faqs/FAQs.js"));
const virtualTour = lazy(() => import("../pages/virtual-tour/VirtualTour.js"));

const PublicSurveyList = lazy(() =>
  import("../pages/surveys/public-surveys/PublicSurveyList.js")
);

//chats

const Treemap = lazy(() => import("../pages/tree/RFTView.js"));

//chats
const Chat = lazy(() => import("../pages/Chat"));

const CriteriaView = lazy(() => import("../pages/Criteria/CriteriaView.js"));
const AddCriteria = lazy(() => import("../pages/Criteria/AddCriteria.js"));
const AddSectionsCriteria = lazy(() =>
  import("../pages/Criteria/AddSectionCriteria.js")
);
const IndividualFeedbackReport = lazy(() =>
  import("../pages/quiz/analyze-responses/reports/IndividualFeedbackReport.js")
);

const authProtectedRoutes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "/dashboard",
    component: DashboardAnalytics,
  },
  {
    path: "/profile/:tabID",
    component: UserProfile,
  },

  {
    path: "/billing",
    component: BillingPayment,
  },
  {
    path: "/view-roles",
    component: RolesView,
  },
  {
    path: "/add-role",
    component: RolesForm,
  },
  {
    path: "/edit-role",
    component: RolesForm,
  },

  {
    path: "/role-permissions",
    component: RolePermissions,
  },

  // {
  //
  // 	path: '/user-role',
  // 	component: UserRoles,
  // },
  // {
  // 	path: '/user-permissions',
  // 	component: UserPermissions,
  // },
  {
    path: "/view-permissions",
    component: PermissionsView,
  },
  {
    path: "/add-permission",
    component: PermissionForm,
  },
  {
    path: "/edit-permission",
    component: PermissionForm,
  },
  {
    path: "/team-management",
    component: TeamView,
  },
  {
    path: "/invite-member",
    component: TeamForm,
  },
  // {
  // ,
  // 	path: '/invite-member/:id',
  // 	component: TeamForm,
  // },
  // {
  // ,
  // 	path: '/member-hierarchy',
  // 	component: TeamHierarchy,
  // },

  { path: "/survey-list", component: SurveyView },
  { path: "/question-bank", component: QuestionBankView },
  {
    path: "/analyze-responses/:id",
    component: AnalyzeResponses,
  },
  {
    path: "/analyze-responses/:id/result/:dashboardID",
    component: SingleDashboardView,
  },
  {
    path: "/analyze-responses/:id/sentiments/:q_id/:q_type",
    component: QuestionSentiments,
  },

  {
    path: "/analyze-responses/quiz/:id",
    component: AnalyzeQuiz,
  },

  {
    path: "/analyze-responses/quiz/:id/result/:dashboardID",
    component: SingleDashboardQuiz,
  },

  {
    path: "/initiate-survey",
    component: CreateSurveyInitiate,
  },
  {
    path: "/initiate-quiz",
    component: CreateQuizInitiate,
  },
  {
    path: "/initiate-template",
    component: CreateSurveyInitiate,
  },

  {
    path: "/create-survey",
    component: SurveyCreation,
  },
  {
    path: "/edit-survey/:id",
    component: SurveyCreation,
  },

  {
    path: "/survey-preview",
    component: CompleteSurveyView,
  },
  {
    path: "/view-survey/:id",
    component: CompleteSurveyView,
  },
  {
    path: ["/survey/:id/audience", "/quiz/:id/audience"],
    component: AudienceView,
  },

  {
    path: ["/survey/:id/add-audience", "/quiz/:id/add-audience"],
    component: AddAudience,
  },

  {
    path: "/create-quiz",
    component: QuizCreation,
  },
  {
    path: "/edit-quiz/:id",
    component: QuizCreation,
  },

  {
    path: "/quiz-preview",
    component: CompleteQuizView,
  },

  {
    path: "/view-quiz/:id",
    component: CompleteQuizView,
  },

  {
    path: ["/quiz/:id/criteria", "/quiz/:id/sections-criteria"],
    component: CriteriaView,
  },
  {
    path: "/quiz/:id/add-criteria",
    component: AddCriteria,
  },
  {
    path: "/quiz/:id/add-section-criteria",
    component: AddSectionsCriteria,
  },

  {
    path: "/family-tree/add-children",
    component: AddChildNodes,
  },
  {
    path: [
      "/survey/:id/add-attributes",
      "/quiz/:id/add-attributes",
      "/family-tree/add-attributes",
    ],
    component: AddAttributes,
  },
  {
    path: ["/survey/:id/templates", "/quiz/:id/templates"],
    component: TemplateView,
  },
  {
    path: ["/survey/:id/add-email-template", "/quiz/:id/add-email-template"],

    component: AddEmailTemplate,
  },

  {
    path: [
      "/survey/:id/email-template/:temp_id",
      "/quiz/:id/email-template/:temp_id",
    ],

    component: AddEmailTemplate,
  },

  {
    path: "/server-error",
    component: Error500,
  },

  {
    path: "/email-management",
    component: EmailView,
  },

  {
    path: "/add-email",
    component: EmailForm,
  },
  {
    path: "/information",
    component: InformationPage,
  },
  {
    path: "/information/details/:id",
    component: SearchDetails,
  },
  {
    path: "/generate-embedded",
    component: EmbeddedCode,
  },
  {
    path: "/faqs",
    component: faqs,
  },
  {
    path: "/virtual-tour",
    component: virtualTour,
  },
  {
    path: "/user-package",
    component: PackageUpdateView,
  },

  {
    path: "/tree-map",
    component: Treemap,
  },

  {
    path: "/apps-chat/:id",
    component: Chat,
  },
  {
    path: "/individual-report/:quiz_id/:audience_id",
    component: IndividualFeedbackReport,
  },

  {
    path: "*",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/permission-assign", component: PermissionForm },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
  { path: "/surveys-list", component: PublicSurveyList },

  {
    path: "/pl/:user_id/:survey_id/:channel_id",
    component: PublishSurvey,
  },
  {
    path: "/ql/:user_id/:quiz_id/:channel_id",
    component: PublishQuiz,
  },
  {
    path: "/dashboard/:user_id/:dashboard_id",
    component: PublicDashboard,
  },
  {
    path: "/dashboard/quiz/:user_id/:dashboard_id",
    component: PublicDashboardQuiz,
  },

  {
    path: "/sentiments/:user_id/:question_id",
    component: QuestionSentiments,
  },

  //AuthenticationInner pages

  { path: "/email-verification", component: BasicSuccessMsg },
  { path: "/basic-notification", component: Maintenance },
  { path: "/reset-password", component: ResetPasswordForm },
  { path: "/un-authorized", component: Alt401 },
  { path: "/unsubscribe", component: UnsubscribePage },
];

const layoutWisedRoutes = [
  "create-survey",
  "survey-preview",
  "edit-survey",
  "view-survey",
  "create-quiz",
  "edit-quiz",
  "quiz-preview",
  "view-quiz",
  "billing",
  "result",
  "details",
  "sentiments",
  "generate-embedded",
  "apps-chat",
  "individual-report",
];

export { authProtectedRoutes, layoutWisedRoutes, publicRoutes };
