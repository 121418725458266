export const parseErrors = (errObj) => {
  if (errObj?.data?.statusCode == 498) {
    window.location.href = "/login";
    throw new Error("Not authenticated");
  }

  try {
    const { error, statusCode, statusDescription, success } = errObj.data || {};

    switch (errObj.status) {
      case 400:
        return {
          success,
          statusCode,
          message: statusDescription,
          error,
        };
      case 401:
        return {
          success,
          statusCode,
          message: statusCode == 498 ? "Please Login Again" : statusDescription,
          error,
          goto: "/un-authorized",
        };
      case 403:
      case 404:
      case 409:
      case 422:
        return {
          success,
          statusCode,
          message: statusDescription,
          error,
        };

      case 500:
        return {
          success: false,
          message: "Network Error",
          goto: "/server-error",
        };
      case 503:
        return {
          success: false,
          message: "No Internet Access",
          goto: "/server-error",
        };
      default:
        return {
          success: false,
          message: "An error occurred while processing your request.",
        };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred Please Check your Internet Connection",
    };
  }
};
