import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import { getQuizSummaryError, getQuizSummarySuccess } from "./actions";

import { quizSummaryApi } from "../../../../../../services/quiz/quiz-analysis/quizAnalysisApi";
import { GET_QUIZ_SUMMARY_REQUEST } from "./actionTypes";

function* getSummaryOfQuiz({ payload: surveyPayload }) {
  try {
    const response = yield call(quizSummaryApi, {
      ...surveyPayload,
      quiz_id: surveyPayload.survey_id,
    });

    if (response && response.success) {
      yield put(getQuizSummarySuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(getQuizSummaryError(error));
  }
}

function* quizSummarySaga() {
  yield takeEvery(GET_QUIZ_SUMMARY_REQUEST, getSummaryOfQuiz);
}

export default quizSummarySaga;
