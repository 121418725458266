import { create } from "../../common/http.service";

const GET_QUIZ_ANALYSIS_URL = "/get-quiz-feedback-list";
const GET_QUIZ_SUMMARY_URL = "/get-quiz-feedback-analysis-one";
const GET_QUESTION_SENTIMENTS_URL = "/get-question-sentiments";
const GET_FEEDBACK_REPORTS_URL = "/get-quiz-feedback-section-wise";

export const quizAnalysisApi = async (payload) => {
  const res = await create(GET_QUIZ_ANALYSIS_URL, payload, true);

  return res;
};
export const quizSummaryApi = async (payload) => {
  const res = await create(GET_QUIZ_SUMMARY_URL, payload, true);

  return res;
};

export const questionSentimentsAnalysisApi = async (payload) => {
  const res = await create(GET_QUESTION_SENTIMENTS_URL, payload, true);

  return res;
};
export const feedbackReportsApi = async (payload) => {
  const res = await create(GET_FEEDBACK_REPORTS_URL, payload, true);

  return res;
};
