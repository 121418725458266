import { call, put, takeEvery } from "redux-saga/effects";
import { viewSingleSurveyApi } from "../../../../services/surveys/view-survey/viewSurveyApi";
import { initiateAssessmentSuccess } from "../default/actions";

// Login Redux States

import {
  viewSingleAssessmentError,
  viewSingleAssessmentSuccess,
} from "./actions";

import { customAlphabet } from "nanoid";
import { predefined_Questions_Personal } from "../../../../common/demoData";
import { viewSingleQuizApi } from "../../../../services/quiz/view-quiz/viewQuizApi";
import { viewSingleTemplateApi } from "../../../../services/template/templateApis";
import { allQuestionInAssessment } from "../assessment-questionnaire/actions";
import { VIEW_SINGLE_ASSESSMENT_REQUEST } from "./actionTypes";

function* viewAssessment({ payload: surveyID }) {
  let randomId = customAlphabet("1234567890abcdef", 10);
  let isThisQuiz = window.location.pathname.includes("quiz");
  try {
    const response = isThisQuiz
      ? yield call(viewSingleQuizApi, { _id: surveyID._id })
      : surveyID.state
      ? yield call(viewSingleTemplateApi, { _id: surveyID._id })
      : yield call(viewSingleSurveyApi, { _id: surveyID._id });
    if (response && response.success) {
      let { data } = response;
      let {
        survey_questionnaire,
        quiz_questionnaire,
        pre_quiz_questionnaire,
        pre_survey_questionnaire,
        ...rest
      } = data;
      let responseData =
        data.survey_questionnaire || data.quiz_questionnaire || [];
      let allQuestion = [];

      if (
        responseData &&
        responseData.constructor === Array &&
        responseData.length > 0
      ) {
        allQuestion = responseData.map((item) => ({
          ...item,
          keyID: item.keyID ? item.keyID : randomId(),
        }));
      }

      yield put(
        initiateAssessmentSuccess({
          ...rest,
          survey_questionnaire:
            survey_questionnaire || quiz_questionnaire || [],
          pre_survey_questionnaire: pre_survey_questionnaire ||
            pre_quiz_questionnaire || {
              personal: predefined_Questions_Personal,
            },
        })
      );
      yield put(allQuestionInAssessment(allQuestion));
      yield put(viewSingleAssessmentSuccess(response));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(viewSingleAssessmentError(error));
  }
}

function* viewSingleAssessmentSaga() {
  yield takeEvery(VIEW_SINGLE_ASSESSMENT_REQUEST, viewAssessment);
}

export default viewSingleAssessmentSaga;
