import { parseErrors } from "../../utils/utilFunctions";
import AppAxios from "../baseApi";
import verifyCaptchaAxios from "../captchaVerification";

let isInternetConnected = navigator.onLine;

export const get = async (url, isAuthenticated = true, params) => {
  try {
    let res = null;

    if (isInternetConnected) {
      if (isAuthenticated) {
        res = await AppAxios({
          isPrivate: true,
          withCredentials: true,
        }).get(url, {
          params,
        });
      } else {
        res = await AppAxios({}).get(url, { params });
      }

      return {
        success: res.data.success,
        data: res.data.data,
        message: res.data.statusDescription,
        status: res.data.statusCode,
      };
    } else {
      throw {
        data: {
          error: "wuetirw",
          statusCode: 500,
          statusDescription: "ldsfksdflsd",
          success: false,
        },
        status: 503,
      };
    }
  } catch (error) {
    return parseErrors(error);
  }
};

export const create = async (url, data, isAuthenticated = true) => {
  try {
    let res = null;
    if (isInternetConnected) {
      if (isAuthenticated) {
        res = await AppAxios({ isPrivate: true }).post(url, data);
      } else {
        res = await AppAxios({}).post(url, data);
      }
      let user = res.data.accessToken ? res.data.accessToken : null;

      if (res.data.success) {
        return {
          success: res.data.success,
          data: res.data.data,
          message: res.data.statusDescription,
          user,
        };
      } else {
        throw res;
      }
    } else {
      throw {
        data: {
          error: "error",
          statusCode: 500,
          statusDescription: "Check Network Connectivity",
          success: false,
        },
        status: 503,
      };
    }
  } catch (error) {
    if (error?.data?.length > 0) {
      return parseErrors(error);
    } else {
      return parseErrors(error.response);
    }
  }
};

export const createCaptcha = async (url) => {
  try {
    let res = null;
    if (isInternetConnected) {
      res = await verifyCaptchaAxios().post(url);

      if (res.data.success) {
        return res.data;
      } else {
        throw res;
      }
    } else {
      throw {
        data: {
          error: "wuetirw",
          statusCode: 500,
          statusDescription: "ldsfksdflsd",
          success: false,
        },
        status: 503,
      };
    }
  } catch (error) {
    return error;
  }
};

export const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
